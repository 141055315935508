import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom';
import tekelogo from '../../assets/tekelogo.jpg'

export const Header = () => {

    return (
        <Container fluid>
            <Row className="header">
                <Col xs={3}>
                    <img
                        src={tekelogo}
                        alt="logo"
                    ></img>
                </Col>
                <Col  xs={8} style={{ paddingTop: "3.3rem" }}>
                    <Link to="/" className="link">
                        eredmények
                    </Link>
                    <Link to={"/csapatok/126"} className="link">
                        csapatok
                    </Link>
                </Col>
                <Col  xs={1} style={{
                    paddingTop: "2rem",
                    textAlign: "right"
                }}>
                    <a className="fb-pic" href="https://www.facebook.com/tekeszovetseg">
                        <img
                            src="/facebookicon.jpg"
                            alt="fb-logo"
                        ></img>
                        Facebook
                    </a>
                </Col>
            </Row>
        </Container>
    );
};