import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export const GamesShort = (props) => {
  const onPlayerClick = () => {
    window.scrollTo(0, 0);
    props.setShowModal(false)
  }

  return (
    <>
      <div className="title-popup">Meccs részletek</div>
      <Table striped hover bordered responsive size="sm" style={{ fontSize: "0.8rem" }}>
        <thead>
          <tr>
            <th className="pointsShort-grid-item"><div>
              Hazai</div>
              <div className="homepdetail">
                Vendég</div></th>
            <th className="pointsShort-grid-item">Üf</th>
            <th className="pointsShort-grid-item">Össz</th>
            <th className="pointsShort-grid-item">Szp</th>
            <th className="pointsShort-grid-item">Csp</th>
          </tr>
        </thead>
        <tbody>
          {props.oneMatch.map((data) =>
            data.details.map((game) => (
              <tr key={game.id}>
                <td className="pointsShort-grid-item">
                  <div>

                    {game.homesubplayername
                      ? <>
                        <Link to={"/jatekosok/" + game.homeplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.homeplayername}
                        </Link>
                        (<Link to={"/jatekosok/" + game.homesubplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.homesubplayername}
                        </Link>)
                      </>
                      : <Link to={"/jatekosok/" + game.homeplayerid} onClick={onPlayerClick}
                        className="grid2-mid"
                      >
                        {game.homeplayername}
                      </Link>
                    }</div>
                  <div className="homepdetail">

                    {game.awaysubplayername
                      ? <><Link to={"/jatekosok/" + game.awayplayerid} onClick={onPlayerClick}
                        className="grid2-mid"
                      >
                        {game.awayplayername}
                      </Link>(
                        <Link to={"/jatekosok/" + game.awaysubplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.awaysubplayername}
                        </Link>)
                      </>
                      : <Link to={"/jatekosok/" + game.awayplayerid} onClick={onPlayerClick}
                        className="grid2-mid"
                      >
                        {game.awayplayername}
                      </Link>}
                  </div>
                </td>
                <td className="pointsShort-grid-item">
                  <div className="awaypdetail">
                    {game.playerdetails.map(
                      (pdetails) => pdetails.homeuf + " "
                    )}
                  </div>
                  <div className="homepdetail">
                    {game.playerdetails.map(
                      (pdetails) => pdetails.awayuf + " "
                    )}
                  </div>
                </td>
                <td className="pointsShort-grid-item">
                  <div className="awaypdetail">{game.homeuf}</div>
                  <div className="homepdetail">{game.awayuf}</div>
                </td>
                <td className="pointsShort-grid-item">
                  <div className="awaypdetail">{game.homeszp}</div>
                  <div className="homepdetail">{game.awayszp}</div>
                </td>
                <td className="pointsShort-grid-item">
                  <div className="awaypdetail">{game.homecsp}</div>
                  <div className="homepdetail">{game.awaycsp}</div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};
