import "../assets/App.css";
import "../assets/index.css";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Merkozesek } from "../components/Merkozesek";
import { Stats } from "../components/Stats";
import { ContentHandler } from "../components/ContentHandler";
import { KeresztTabla } from "../components/KeresztTabla";
import Table from "react-bootstrap/Table";
import { Footer } from "../components/Footer";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import HeaderContentHandler from "../components/HeaderContentHandler";
import { Link } from "react-router-dom";

const Home = () => {
  const [eredmenyek, setEredmenyek] = useState([]);
  const [merkozesek, setMerkozesek] = useState([
    { home: "teszt", away: "Nyir Teszt" },
  ]);
  const [tablaState, setTablaState] = useState(true);

  const [evadok, setEvadok] = useState([]);
  const [selectedEvad, setSelectedEvad] = useState({
    id: 15,
    year: "2024-2025",
  });
  const [tipusok, setTipusok] = useState([]);
  const [selectedTipus, setSelectedTipus] = useState({ id: 1, name: "Férfi" });
  const [bajnoksagok, setBajnoksagok] = useState([]);
  const [selectedBajnoksag, setSelectedBajnoksag] = useState({
    id: 10,
    name: "SZUPERLIGA",
  });
  const [fordulok, setFordulok] = useState([]);
  const [selectedFordulo, setSelectedFordulo] = useState({ round: "1" });
  const [csapatok, setCsapatok] = useState([{ name: "Nyir Teszt" }]);
  const [selectedCsapat, setSelectedCsapat] = useState({
    bunteto: 0.0,
    buntetocsp: 0.0,
    buntetocsvp: 0.0,
    buntetodraw: 0.0,
    buntetonym: 0.0,
    buntetoszp: 0.0,
    buntetovm: 0.0,
    buntetovp: 0.0,
    championshipid: 23,
    egyesulet: "Zengö Alföld Szegedi TE",
    id: 126,
    name: "Zengő Alföld Szegedi TE",
    review: null,
    userid: 27,
  });
  const [jatekosok, setJatekosok] = useState([]);
  const [teljestabla, setTeljestabla] = useState(true);
  const [keresztTabla, setKeresztTabla] = useState(true);

  //SEASON/EVAD + TYPE/TIPUS
  useEffect(() => {
    const evadURL = "https://csaka9.hu/teke/get/season";
    const tipusURL = "https://csaka9.hu/teke/get/type";

    const fetchEvad = async () => {
      const response = await fetch(evadURL);
      const newData = await response.json();
      setEvadok(newData);
    };

    const fetchTipus = async () => {
      const response = await fetch(tipusURL);
      const newData = await response.json();
      setTipusok(newData);
    };

    fetchEvad();
    fetchTipus();
  }, [selectedEvad, selectedTipus]);

  //CHAMPIONSHIP
  useEffect(() => {
    //https://csaka9.hu/teke/championship/<path:typeid>/<path:seasonid>

    const bajnoksagURL =
      "https://csaka9.hu/teke/championship/" +
      selectedTipus.id +
      "/" +
      selectedEvad.id;

    const fetchBajnoksag = async () => {
      const response = await fetch(bajnoksagURL);
      const newData = await response.json();
      if (newData[0] !== undefined) {
        setBajnoksagok(newData);
        setSelectedBajnoksag(newData[0]);
      } else {
        setBajnoksagok([
          {
            id: 1,
            name: "NINCS ILYEN BAJNOKSÁG",
          },
        ]);
        setSelectedBajnoksag({
          id: 0,
          name: "NINCS ILYEN BAJNOKSÁG",
        });
      }
    };

    fetchBajnoksag();
  }, [selectedEvad, selectedTipus, evadok]);

  // ROUND + TEAM
  useEffect(() => {
    if (selectedBajnoksag.id !== 0) {
      //@app.route('/round/<path:id>', methods = [ 'GET'])
      const forduloURL =
        "https://csaka9.hu/teke/defround/" + selectedBajnoksag.id;

      //@app.route('/team/<path:champid>', methods = [ 'GET','POST','DELETE'])
      const csapatokURL = "https://csaka9.hu/teke/team/" + selectedBajnoksag.id;

      const fetchFordulo = async () => {
        const response = await fetch(forduloURL);
        const newData = await response.json();
        setFordulok(newData.fordulok);
        setSelectedFordulo(newData.default[0]);
      };
      const fetchCsapatok = async () => {
        const response = await fetch(csapatokURL);
        const newData = await response.json();
        setCsapatok(newData);
        setSelectedCsapat(newData[0]);
      };

      fetchFordulo();
      fetchCsapatok();
    }
  }, [selectedEvad, selectedTipus, selectedBajnoksag]);

  // TEAM_PLAYER + SETSELECTEDPLAYER
  useEffect(() => {
    if (selectedCsapat.id !== undefined) {
      //@app.route('/team_player/<path:csapatid>', methods = [ 'GET'])
      const jatekosokURL =
        "https://csaka9.hu/teke/team_player/" + selectedCsapat.id;

      const fetchJatekosok = async () => {
        const response = await fetch(jatekosokURL);
        const newData = await response.json();
        setJatekosok(newData);
      };

      fetchJatekosok();
    }
  }, [selectedCsapat]);

  //FOR OPENING TABLE ON PHONE
  useEffect(() => {
    // "mql" = "media query list".
    const mql = window.matchMedia("(max-width: 768px)");
    if (mql.matches) {
      setTeljestabla(false);
    }
  }, []);

  //RESULTS + GAMEALL(!)
  useEffect(() => {
    if (selectedFordulo.round !== "NINCS ILYEN FORDULÓ") {
      //@app.route('/results/<path:fordulo>/<path:bajnoksagid>', methods = [ 'GET'])
      const eredmenyURL =
        "https://csaka9.hu/teke/results/" +
        selectedFordulo.round +
        "/" +
        selectedBajnoksag.id;

      //@app.route('/gameall/<path:fordulo>/<path:bajnoksagid>', methods = [ 'GET'])
      const merkozesURL =
        "https://csaka9.hu/teke/gameall/" +
        selectedFordulo.round +
        "/" +
        selectedBajnoksag.id;

      const fetchEredmeny = async () => {
        const response = await fetch(eredmenyURL);
        const newData = await response.json();
        if (newData !== "ok") {
          setEredmenyek(newData);
        }
      };

      const fetchMerkozes = async () => {
        const response = await fetch(merkozesURL);
        const newData = await response.json();
        setMerkozesek(newData);
      };

      fetchEredmeny();
      fetchMerkozes();
    }
  }, [selectedEvad, selectedTipus, selectedBajnoksag, selectedFordulo]);

  //kereszttábla
  useEffect(() => {
    if (selectedFordulo.round !== "NINCS ILYEN FORDULÓ") {
      //@app.route('/kereszttabla/<path:round>/<path:champid>', methods = [ 'GET' ])
      const keresztTablaURL =
        "https://csaka9.hu/teke/kereszttabla/" +
        selectedFordulo.round +
        "/" +
        selectedBajnoksag.id;

      const fetchKeresztTabla = async () => {
        const response = await fetch(keresztTablaURL);
        const newData = await response.json();
        setKeresztTabla(newData);
      };

      fetchKeresztTabla();
    }
  }, [selectedEvad, selectedTipus, selectedBajnoksag, selectedFordulo]);

  const tablaFordito = () => {
    setTablaState(!tablaState);
  };

  return (
    <>
      <HeaderContentHandler teljestabla={teljestabla} />
      <Container fluid>
        <div>
          <Row>
            <div className="dropdown-titles">
              <div className="dropdown-title-item">Évad:</div>
              <div className="dropdown-title-item">Típus:</div>
              <div className="dropdown-title-item">Bajnokság:</div>
              <div className="dropdown-title-item">Forduló:</div>
            </div>
          </Row>
          <Row>
            <div className="buttons">
              <DropdownButton
                className="button1"
                id="dropdown-variants-secondary"
                variant="secondary"
                title={selectedEvad.year}
              >
                {evadok.map((data, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => setSelectedEvad(data)}
                  >
                    {data.year}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                className="button1"
                id="dropdown-variants-secondary"
                variant="secondary"
                title={selectedTipus.name}
              >
                {tipusok.map((data, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => setSelectedTipus(data)}
                  >
                    {data.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                className="button1"
                id="dropdown-variants-secondary"
                variant="secondary"
                style={{ maxWidth: "25%" }}
                title={selectedBajnoksag.name}
              >
                {bajnoksagok.map((data, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => setSelectedBajnoksag(data)}
                  >
                    {data.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                className="button1"
                id="dropdown-variants-secondary"
                variant="secondary"
                title={selectedFordulo.round}
              >
                {fordulok.map((data, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => setSelectedFordulo(data)}
                  >
                    {data.round}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </Row>
        </div>
      </Container>
      <Col>
        {tablaState === true ? (
          <>
            <div className="keresztTablaBalra clear">Bajnokság Állása</div>
            <div className="keresztTablaJobbraHover" onClick={tablaFordito}>
              Kereszttáblához
              <BsFillArrowRightCircleFill />
            </div>
            <div className="clear"></div>
            <Row className="justify-content-center">
              <ContentHandler
                value={teljestabla}
                eredmenyek={eredmenyek}
                selectedCsapat={selectedCsapat}
              />
            </Row>
          </>
        ) : (
          <>
            <div
              className="keresztTablaBalraHover clear"
              onClick={tablaFordito}
            >
              <BsFillArrowLeftCircleFill />
              Bajnokság Állásához
            </div>
            <div className="keresztTablaJobbra">Kereszttábla</div>
            <div style={{ justifyContent: "center", width: "100%" }}>
              <KeresztTabla
                keresztTabla={keresztTabla}
                selectedEvad={selectedEvad}
                csapatok={csapatok}
              />
            </div>
          </>
        )}
      </Col>
      <Container style={{ maxWidth: "90%" }}>
        <Row>
          <Col sm={8}>
            <div className="title">Mérkőzések</div>
            <Merkozesek merkozesek={merkozesek} />
            <Stats
              selectedFordulo={selectedFordulo.round}
              selectedBajnoksag={selectedBajnoksag.id}
            />
          </Col>
          <Col sm={4}>
            <div className="title">Játékosok</div>
            <DropdownButton
              id="dropdown-variants-secondary"
              variant="secondary"
              title={selectedCsapat.name}
              className="button1"
            >
              {csapatok.map((data, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => setSelectedCsapat(data)}
                >
                  {data.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <div className="grid3">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th className="grid2-mid">Név</th>
                  </tr>
                </thead>
                <tbody>
                  {jatekosok.map((data) => (
                    <tr key={data.id.toString()}>
                      {data.name !== "Nem Jelent meg" && (
                        <td className="grid2-mid">
                          <Link
                            to={"/jatekosok/" + data.id}
                            className="grid2-mid"
                          >
                            {data.name}
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
