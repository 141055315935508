import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from 'react-router-dom';
import tekelogo from '../../assets/tekelogo.jpg'
import Col from "react-bootstrap/Col";

export const HeaderShort = () => {

    const navSlide = () => {
        const burger = document.querySelector('.burger');
        const nav = document.querySelector('.nav-links');
        const navLinks = document.querySelectorAll('.nav-links li');

        nav.classList.toggle('nav-active');

        navLinks.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = ``;
            } else {
                link.style.animation = `navLinkFade 0.5s ease forwards &{index / 7 + 0.3}s`;
            }
        });

        burger.classList.toggle('toggle');
    }

    return (
        <Container fluid style={{ maxWidth: "100%" }}>
            <Row className="justify-content-around" style={{ height: "10vh" }}>
                <Col>
                    <img
                        src={tekelogo}
                        alt="logo-temporary"
                        className="logo-short"
                    ></img>
                </Col>
                <ul className="nav-links">
                    <Link to="/" onClick={navSlide}>
                        Eredmények
                    </Link>
                    <Link to={"/csapatok/126"} onClick={navSlide}>
                        Csapatok
                    </Link>
                </ul>
                <div onClick={navSlide} className="burger">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </Row>
        </Container>
    );
};