import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Footer } from "../components/Footer";
import teamIconTemp from "../assets/team-icon.png";
import HeaderContentHandler from "../components/HeaderContentHandler";
import {
  GiBowlingStrike,
  GiLaurelCrown,
  GiBowlingAlley,
  GiStrikingBalls,
} from "react-icons/gi";
import Table from "react-bootstrap/Table";
import { Modal } from "../components/Modal";

const Csapatok = () => {
  const [evadok, setEvadok] = useState([]);
  const [selectedEvad, setSelectedEvad] = useState({
    id: 15,
    year: "2024-2025",
  });
  const [tipusok, setTipusok] = useState([]);
  const [selectedTipus, setSelectedTipus] = useState({ id: 1, name: "Férfi" });
  const [bajnoksagok, setBajnoksagok] = useState([]);
  const [selectedBajnoksag, setSelectedBajnoksag] = useState({
    id: 10,
    name: "SZUPERLIGA",
  });
  const [csapatok, setCsapatok] = useState([{ name: "Nyir Teszt" }]);
  const [jatekosok, setJatekosok] = useState([]);
  const [osszesCsapatMerkozes, setOsszesCsapatMerkozes] = useState([]);
  const [csapatStats, setCsapatStats] = useState({
    hazaiatlag: 3341.2,
    hazaimeccsszam: 4,
    hazaiossz: 13365,
    legjobb_jatekosok: [
      { avgem: 649.0, name: "Danóczy Richárd" },
      { avgem: 611.0, name: "Csejtei Norbert" },
      { avgem: 594.0, name: "Bíró Patrik" },
    ],
    legtobbpont: 3485,
    legtobbpontdatum: "2022-10-22 11:00:00",
    osszatlag: 3366.8571428571427,
    osszmeccs: 7,
    osszpont: 23568,
    vendegatlag: 3401.0,
    vendegmeccsszam: 3,
    vendegossz: 10203,
  });
  const [csapatAllStats, setCsapatAllStats] = useState([
    {
      hazaiatlag: 3341.2,
      hazaimeccsszam: 4,
      legtobbpont: 3485,
      legtobbpontdatum: "2022-10-22 11:00:00",
      osszatlag: 3366.8571428571427,
      osszmeccs: 7,
      vendegatlag: 3401.0,
      vendegmeccsszam: 3,
    },
  ]);
  const { csapatId } = useParams();
  const [csapatIdNumber, setCsapatIdNumber] = useState();
  const [reverse, setReverse] = useState();
  const [selectedModal, setSelectedModal] = useState();
  const [showModal, setShowModal] = useState();
  const [teljestabla, setTeljestabla] = useState(true);
  const [csapatKep, setCsapatKep] = useState();

  //FOR OPENING TABLE ON PHONE
  useEffect(() => {
    // "mql" = "media query list".
    const mql = window.matchMedia("(max-width: 768px)");
    if (mql.matches) {
      setTeljestabla(false);
    }
  }, []);

  const openModal = (data) => {
    setShowModal(true);
    setSelectedModal(data.id);
  };

  useEffect(() => {
    if (csapatId) {
      const number = parseInt(csapatId);
      if (!isNaN(number)) {
        setCsapatIdNumber(number);
      }
    }
  }, [csapatId]);

  //REVERSE (VÁLASZTÓK)
  useEffect(() => {
    const reverseURL = "https://csaka9.hu/teke/team_reverse/" + csapatIdNumber;

    const fetchReverse = async () => {
      const response = await fetch(reverseURL);
      const newData = await response.json();
      setReverse(newData[0]);
    };

    if (csapatIdNumber) {
      fetchReverse();
    }
  }, [csapatIdNumber]);

  //SEASON/EVAD + TYPE/TIPUS
  useEffect(() => {
    const evadURL = "https://csaka9.hu/teke/get/season";
    const tipusURL = "https://csaka9.hu/teke/get/type";

    const fetchEvad = async () => {
      const response = await fetch(evadURL);
      const newData = await response.json();
      setEvadok(newData);
      setSelectedEvad(newData[2]);
    };

    const fetchTipus = async () => {
      const response = await fetch(tipusURL);
      const newData = await response.json();
      setTipusok(newData);
      setSelectedTipus(newData[0]);
    };

    fetchEvad();
    fetchTipus();
  }, [reverse]);

  //CHAMPIONSHIP
  useEffect(() => {
    //https://csaka9.hu/teke/championship/<path:typeid>/<path:seasonid>

    const bajnoksagURL =
      "https://csaka9.hu/teke/championship/" +
      selectedTipus.id +
      "/" +
      selectedEvad.id;

    const fetchBajnoksag = async () => {
      const response = await fetch(bajnoksagURL);
      const newData = await response.json();
      setBajnoksagok(newData);
      setSelectedBajnoksag(newData[0]);
    };

    fetchBajnoksag();
  }, [selectedEvad, selectedTipus, reverse]);

  // ROUND + TEAM
  useEffect(() => {
    if (selectedBajnoksag.id !== 0) {
      //@app.route('/team/<path:champid>', methods = [ 'GET','POST','DELETE'])
      const csapatokURL = "https://csaka9.hu/teke/team/" + selectedBajnoksag.id;

      const fetchCsapatok = async () => {
        const response = await fetch(csapatokURL);
        const newData = await response.json();
        setCsapatok(newData);
      };

      fetchCsapatok();
    }
  }, [selectedEvad, selectedTipus, selectedBajnoksag, csapatIdNumber]);

  // TEAM_PLAYER
  useEffect(() => {
    if (csapatIdNumber !== undefined) {
      //@app.route('/team_player/<path:csapatid>', methods = [ 'GET'])
      const jatekosokURL =
        "https://csaka9.hu/teke/team_player/" + csapatIdNumber;

      const fetchJatekosok = async () => {
        const response = await fetch(jatekosokURL);
        const newData = await response.json();
        setJatekosok(newData);
      };

      fetchJatekosok();
    }
  }, [csapatIdNumber]);

  // GETGAMEALL(csapat összes meccsei)
  useEffect(() => {
    if (csapatIdNumber) {
      //@app.route('/gameallteam/<path:teamid>', methods = [ 'GET'])
      const osszesCsapatMerkozesURL =
        "https://csaka9.hu/teke/gameallteam/" + csapatIdNumber;

      const fetchOsszesCsapatMerkozes = async () => {
        const response = await fetch(osszesCsapatMerkozesURL);
        const newData = await response.json();
        setOsszesCsapatMerkozes(newData);
      };

      fetchOsszesCsapatMerkozes();
    }
  }, [csapatIdNumber]);

  // GETCSAPATSTATS
  useEffect(() => {
    if (reverse) {
      //@app.route('/gameallstats/<path:teamid>/<path:champid>', methods = [ 'GET'])
      const csapatStatsURL =
        "https://csaka9.hu/teke/gameallstats/" +
        csapatIdNumber +
        "/" +
        reverse.bajnoksagid;

      const fetchCsapatStats = async () => {
        const response = await fetch(csapatStatsURL);
        const newDataCsapatStats = await response.json();
        if (newDataCsapatStats.hazaiatlag !== 0) {
          setCsapatStats(newDataCsapatStats);
        }
      };

      fetchCsapatStats();
    }
  }, [csapatIdNumber, reverse]);

  // GETCSAPAT STATISZTIKÁK ALULRA
  useEffect(() => {
    if (csapatIdNumber) {
      //@app.route('/gameallstats/<path:csapatid>', methods = [ 'GET'])
      const csapatAllStatsURL =
        "https://csaka9.hu/teke/allgameallstats/" + csapatIdNumber;

      const fetchCsapatAllStats = async () => {
        const response = await fetch(csapatAllStatsURL);
        const newData = await response.json();
        if (newData.hazaiatlag !== 0) {
          setCsapatAllStats(newData);
        }
      };

      fetchCsapatAllStats();
    }
  }, [csapatIdNumber]);

  // GETCSAPATKÉP
  useEffect(() => {
    if (reverse) {
      //@app.route('/team/<path:champid>', methods = [ 'GET','POST','DELETE'])
      const csapatKepURL = "https://csaka9.hu/teke/team/" + reverse.bajnoksagid;

      const fetchCsapatKep = async () => {
        const response = await fetch(csapatKepURL);
        const newData = await response.json();
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].id === csapatIdNumber) {
            setCsapatKep(newData[i].teampic);
          }
        }
      };

      fetchCsapatKep();
    }
  }, [reverse, csapatIdNumber]);

  return (
    <>
      <HeaderContentHandler teljestabla={teljestabla} />
      <Container fluid>
        <Row>
          <div className="dropdown-titles">
            <div className="dropdown-title-item-teams">Évad:</div>
            <div className="dropdown-title-item-teams">Típus:</div>
            <div className="dropdown-title-item-teams">Bajnokság:</div>
          </div>
        </Row>
        <Row>
          <div className="buttons">
            <DropdownButton
              className="button1"
              id="dropdown-variants-secondary"
              variant="secondary"
              title={selectedEvad.year}
            >
              {evadok.map((data, idx) => (
                <Dropdown.Item key={idx} onClick={() => setSelectedEvad(data)}>
                  {data.year}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              className="button1"
              id="dropdown-variants-secondary"
              variant="secondary"
              title={selectedTipus.name}
              style={{ maxWidth: "33%" }}
            >
              {tipusok.map((data, idx) => (
                <Dropdown.Item key={idx} onClick={() => setSelectedTipus(data)}>
                  {data.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              className="button1"
              id="dropdown-variants-secondary"
              variant="secondary"
              title={selectedBajnoksag.name}
              style={{ maxWidth: "33%" }}
            >
              {bajnoksagok.map((data, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => setSelectedBajnoksag(data)}
                >
                  {data.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </Row>
      </Container>
      <Container style={{ maxWidth: "90%" }}>
        <Row className="justify-content-around">
          {csapatok.map((data, idx) =>
            data.id === csapatIdNumber ? (
              <div className="card-container-selected" key={idx}>
                <img
                  src={
                    data.logopic
                      ? "https://feltoltes.csaka9.hu/" + data.logopic
                      : teamIconTemp
                  }
                  alt="logo-temporary"
                  className="logo-temporary"
                ></img>
                <div className="card-text">{data.name}</div>
              </div>
            ) : (
              <Link
                to={"/csapatok/" + data.id}
                className="card-container"
                key={idx}
              >
                <img
                  src={
                    data.logopic
                      ? "https://feltoltes.csaka9.hu/" + data.logopic
                      : teamIconTemp
                  }
                  alt="logo-temporary"
                  className="logo-temporary"
                ></img>
                <div className="card-text">{data.name}</div>
              </Link>
            )
          )}
        </Row>
        <Row>
          <Col sm={8}>
            <div className="title title1">Statisztikák</div>
            {csapatStats && (
              <>
                <Row className="justify-content-around">
                  <div className="team-stat-card-container">
                    <div className="team-stat-card">
                      <div>Hazai Átlag</div>
                      <div style={{ textAlign: "center" }}>
                        {Math.round(csapatStats.hazaiatlag)}
                      </div>
                    </div>
                    <div className="team-stat-card">
                      <GiBowlingAlley size={55} />
                    </div>
                    <div className="team-stat-card">
                      <div>Vendég Átlag</div>
                      <div style={{ textAlign: "center" }}>
                        {Math.round(csapatStats.vendegatlag)}
                      </div>
                    </div>
                  </div>
                  <div className="team-stat-card-container">
                    <div className="team-stat-card">Összesített Átlag</div>
                    <div className="team-stat-card">
                      <GiBowlingStrike size={60} />
                    </div>
                    <div className="team-stat-card">
                      {Math.round(csapatStats.osszatlag)}
                    </div>
                  </div>
                  <div className="team-stat-card-container">
                    <div className="team-stat-card">Top csapat fa</div>
                    <div>
                      <GiLaurelCrown size={70} />
                    </div>
                    <div className="team-stat-card">
                      <div style={{ textAlign: "center" }}>
                        {Math.round(csapatStats.legtobbpont)}
                      </div>
                      <div>{csapatStats.legtobbpontdatum.slice(0, 10)}</div>
                    </div>
                  </div>
                  <div className="team-stat-card-container-top-players">
                    <div className="team-stat-card">Top játékosok - Átlag</div>
                    <div>
                      <GiStrikingBalls size={60} />
                    </div>
                    <div className="team-stat-card">
                      <div style={{ textAlign: "center" }}>
                        {csapatStats.legjobb_jatekosok &&
                          csapatStats.legjobb_jatekosok[0] && (
                            <>
                              {csapatStats.legjobb_jatekosok[0].name && (
                                <>
                                  {csapatStats.legjobb_jatekosok[0].name} -{" "}
                                  {csapatStats.legjobb_jatekosok[0].avgem}
                                </>
                              )}
                            </>
                          )}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {csapatStats.legjobb_jatekosok &&
                          csapatStats.legjobb_jatekosok[1] && (
                            <>
                              {csapatStats.legjobb_jatekosok[1].name && (
                                <>
                                  {csapatStats.legjobb_jatekosok[1].name} -{" "}
                                  {csapatStats.legjobb_jatekosok[1].avgem}
                                </>
                              )}
                            </>
                          )}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {csapatStats.legjobb_jatekosok &&
                          csapatStats.legjobb_jatekosok[2] && (
                            <>
                              {csapatStats.legjobb_jatekosok[2].name && (
                                <>
                                  {csapatStats.legjobb_jatekosok[2].name} -{" "}
                                  {csapatStats.legjobb_jatekosok[2].avgem}
                                </>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  {csapatKep && (
                    <img
                      src={
                        "https://feltoltes.csaka9.hu/" + csapatKep
                      }
                      alt="csapat"
                      className="csapatkep"
                    ></img>
                  )}
                </Row>
              </>
            )}
          </Col>
          <Col sm={4}>
            <div className="title title1">Játékosok</div>
            <Table striped bordered hover size="sm">
              <tbody>
                {jatekosok &&
                  jatekosok.length > 0 &&
                  jatekosok.map((data) => (
                    <tr key={data.id.toString()}>
                      {data.name !== "Nem Jelent meg" && (
                        <td className="grid2-mid">
                          <Link to={"/jatekosok/" + data.id}>{data.name}</Link>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <div className="title title1">Csapat Mérkőzései</div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th className="grid2-mid">Forduló</th>
                <th className="grid2-mid">Hazai</th>
                <th className="grid2-mid">Eredmény</th>
                <th className="grid2-mid">Vendég</th>
                <th className="grid2-mid">Helyszín</th>
                <th className="grid2-mid">Dátum</th>
              </tr>
            </thead>
            <tbody>
              {osszesCsapatMerkozes &&
                osszesCsapatMerkozes.length > 0 &&
                osszesCsapatMerkozes.map((data, idx) => (
                  <tr
                    key={idx}
                    className="popup"
                    onClick={() => openModal(data)}
                  >
                    <td className="grid2-mid">{data.round}</td>
                    <td className="grid2-mid">{data.home}</td>
                    <td className="grid2-mid">
                      {data.homeSumCsp}-{data.awaySumCsp}
                    </td>
                    <td className="grid2-mid">{data.away}</td>
                    <td className="grid2-mid">{data.place}</td>
                    {data.matchdate ? (
                      <td className="grid2-mid">
                        {data.matchdate.slice(12, 16)}-
                        {data.matchdate.slice(8, 11)}-
                        {data.matchdate.slice(5, 7)}
                      </td>
                    ) : null}
                  </tr>
                ))}
              {showModal ? (
                <Modal
                  selectedModal={selectedModal}
                  setShowModal={setShowModal}
                  teljestabla={teljestabla}
                />
              ) : null}
            </tbody>
          </Table>
        </Row>
        <Row>
          <div className="title title1">Szezon Bontás</div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th className="grid2-mid kicsi">Évad</th>
                <th className="grid2-mid kicsi">Össz Meccsek</th>
                <th className="grid2-mid kicsi">Össz Átlag</th>
                <th className="grid2-mid kicsi">Hazai Meccsek</th>
                <th className="grid2-mid kicsi">Hazai Átlag</th>
                <th className="grid2-mid kicsi">Vendég Meccsek</th>
                <th className="grid2-mid kicsi">Vendég Átlag</th>
                <th className="grid2-mid kicsi">Legtöbb ütött fa</th>
                <th className="grid2-mid kicsi">Legtöbb ütött fa Dátuma</th>
              </tr>
            </thead>
            <tbody>
              {csapatAllStats &&
                csapatAllStats.length > 0 &&
                csapatAllStats.map((data, idx) => (
                  <tr key={idx}>
                    <td className="grid2-mid kicsi">{data.evad}</td>
                    <td className="grid2-mid kicsi">{data.osszmeccs}</td>
                    <td className="grid2-mid kicsi">
                      {data.osszatlag.toFixed(1)}
                    </td>
                    <td className="grid2-mid kicsi">{data.hazaimeccsszam}</td>
                    <td className="grid2-mid kicsi">
                      {data.hazaiatlag.toFixed(1)}
                    </td>
                    <td className="grid2-mid kicsi">{data.vendegmeccsszam}</td>
                    <td className="grid2-mid kicsi">
                      {data.vendegatlag.toFixed(1)}
                    </td>
                    <td className="grid2-mid kicsi">{data.legtobbpont}</td>
                    {data.legtobbpontdatum ? (
                      <td className="grid2-mid kicsi">
                        {data.legtobbpontdatum.slice(0, 10)}
                      </td>
                    ) : null}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Csapatok;
