import Table from "react-bootstrap/Table";
import React, { useState } from "react";
import { Modal } from "./Modal";

export const Merkozesek = (props) => {
  const [selectedModal, setSelectedModal] = useState();
  const [showModal, setShowModal] = useState();

  const openModal = (data) => {
    setShowModal(true);
    setSelectedModal(data.id);
  };

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th className="grid2-mid">Hazai</th>
          <th className="grid2-mid">Eredmény</th>
          <th className="grid2-mid">Vendég</th>
          <th className="grid2-mid">Helyszín</th>
          <th className="grid2-mid">Dátum</th>
        </tr>
      </thead>
      <tbody>
        {props.merkozesek.map((data, idx) => (
          <tr className="popup" key={idx} onClick={() => openModal(data)}>
            <td className="grid2-mid">{data.home}</td>
            <td className="grid2-mid">
              {data.homeSumCsp}-{data.awaySumCsp}
            </td>
            <td className="grid2-mid">{data.away}</td>
            <td className="grid2-mid">{data.place}</td>
            {data.matchdate ? (
              <td className="grid2-mid">
                {data.matchdate.slice(12, 16)}-{data.matchdate.slice(8, 11)}-
                {data.matchdate.slice(5, 7)}-
                {parseInt(data.matchdate.slice(17, 19)) + 2}:
                {data.matchdate.slice(20, 22)}
              </td>
            ) : null}
          </tr>
        ))}
        {showModal ? (
          <Modal
            selectedModal={selectedModal}
            setShowModal={setShowModal}
            teljestabla={props.teljestabla}
          />
        ) : null}
      </tbody>
    </Table>
  );
};
