import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Footer } from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import HeaderContentHandler from "../components/HeaderContentHandler";
import { Link, useParams } from "react-router-dom";
import profilPictureTemp from "../assets/profile_picture.jfif";
import teamIcon from "../assets/team-icon.png";
import Table from "react-bootstrap/Table";
import { Modal } from "../components/Modal";

const Jatekosok = () => {
  const [evadok, setEvadok] = useState([]);
  const [selectedEvad, setSelectedEvad] = useState({
    id: 15,
    year: "2024-2025",
  });
  const [selectedEvadStats, setSelectedEvadStats] = useState([]);
  const [csapatKep, setCsapatKep] = useState([]);
  const [csapatName, setCsapatName] = useState([]);
  const [jatekosok, setJatekosok] = useState([]);
  const [teljestabla, setTeljestabla] = useState(true);
  const [playerOverallStats, setPlayerOverallStats] = useState([
    {
      evad: "2021-2022",
      hazaiatlag: 553.2,
      hazaimeccsszam: 5,
      hazaiossz: 2766,
      legtobbpont: 586,
      legtobbpontdatum: "2022-03-26 10:00:00",
      osszatlag: 555.6666666666666,
      osszmeccs: 9,
      osszpont: 5001,
      vendegatlag: 558.75,
      vendegmeccsszam: 4,
      vendegossz: 2235,
    },
    {
      evad: "2022-2023",
      hazaiatlag: 574.6,
      hazaimeccsszam: 5,
      hazaiossz: 2873,
      legtobbpont: 586,
      legtobbpontdatum: "2022-11-05 12:00:00",
      osszatlag: 565.5555555555555,
      osszmeccs: 9,
      osszpont: 5090,
      vendegatlag: 554.25,
      vendegmeccsszam: 4,
      vendegossz: 2217,
    },
    {
      evad: "Összesen",
      hazaiatlag: 563.9,
      hazaimeccsszam: 10,
      hazaiossz: 5639,
      legtobbpont: 586,
      legtobbpontdatum: "2022-11-05 12:00:00",
      osszatlag: 560.6111111111111,
      osszmeccs: 18,
      osszpont: 10091,
      vendegatlag: 556.5,
      vendegmeccsszam: 8,
      vendegossz: 4452,
    },
  ]);
  const [playerGames, setPlayerGames] = useState([]);

  const [selectedModal, setSelectedModal] = useState();
  const [showModal, setShowModal] = useState();

  const { playerId } = useParams();
  const [playerIdNumber, setPlayerIdNumber] = useState(null);
  const [reverse, setReverse] = useState({
    bajnoksagid: 23,
    csapatid: 126,
    evadid: 13,
    tipusid: 1,
  });
  const [selectedPlayerPic, setSelectedPlayerPic] = useState([]);
  const [selectedPlayerName, setSelectedPlayerName] = useState([]);

  useEffect(() => {
    if (playerId) {
      const number = parseInt(playerId);
      if (!isNaN(number)) {
        setPlayerIdNumber(number);
      }
    }
  }, [playerId]);

  const openModal = (data) => {
    setShowModal(true);
    setSelectedModal(data.id);
  };

  //REVERSE (VÁLASZTÓK)
  useEffect(() => {
    const reverseURL =
      "https://csaka9.hu/teke/player_reverse/" + playerIdNumber;

    const fetchReverse = async () => {
      const response = await fetch(reverseURL);
      const newData = await response.json();
      setReverse(newData[0]);
    };

    if (playerIdNumber != null) {
      fetchReverse();
    }
  }, [playerIdNumber]);

  //FOR OPENING TABLE ON PHONE
  useEffect(() => {
    // "mql" = "media query list".
    const mql = window.matchMedia("(max-width: 768px)");
    if (mql.matches) {
      setTeljestabla(false);
    }
  }, []);

  //EVAD
  useEffect(() => {
    const evadURL = "https://csaka9.hu/teke/get/season";

    const fetchEvad = async () => {
      const response = await fetch(evadURL);
      const newData = await response.json();
      setEvadok(newData);
    };

    fetchEvad();
  }, [selectedEvad, reverse]);

  //PlayerALLStats + selectedEvadStats
  useEffect(() => {
    if (playerIdNumber > 0) {
      //@app.route('/allgameallstatsplayer/<path:playerid>', methods = [ 'GET'])
      const PlayerOverallStatsURL =
        "https://csaka9.hu/teke/allgameallstatsplayer/" + playerIdNumber;

      const fetchPlayerOverallStats = async () => {
        const response = await fetch(PlayerOverallStatsURL);
        const newData = await response.json();

        setPlayerOverallStats(newData);

        for (let i = 0; i < newData.length; i++) {
          if (newData[i].evad === selectedEvad.year) {
            setSelectedEvadStats(newData[i]);
          }
        }
      };

      fetchPlayerOverallStats();
    }
  }, [playerIdNumber, selectedEvad]);

  // SETSELECTEDPLAYER NAME+PIC
  useEffect(() => {
    if (reverse.csapatid) {
      //@app.route('/team_player/<path:csapatid>', methods = [ 'GET'])
      const jatekosokURL =
        "https://csaka9.hu/teke/team_player/" + reverse.csapatid;

      const fetchJatekosok = async () => {
        const response = await fetch(jatekosokURL);
        const newData = await response.json();
        setJatekosok(newData);
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].id === playerIdNumber) {
            setSelectedPlayerName(newData[i].name);
            setSelectedPlayerPic(newData[i].playerpic);
          }
        }
      };

      fetchJatekosok();
    }
  }, [reverse, playerIdNumber]);

  //team_pic
  useEffect(() => {
    if (reverse) {
      //@app.route('/team/<path:champid>', methods = [ 'GET','POST','DELETE'])
      const csapatKepURL = "https://csaka9.hu/teke/team/" + reverse.bajnoksagid;

      const fetchCsapatKep = async () => {
        const response = await fetch(csapatKepURL);
        const newData = await response.json();

        for (let i = 0; i < newData.length; i++) {
          if (newData[i].id === reverse.csapatid) {
            setCsapatKep(newData[i].logopic);
            setCsapatName(newData[i].name);
          }
        }
      };

      fetchCsapatKep();
    }
  }, [reverse]);

  //PlayerGames
  useEffect(() => {
    //@app.route('/gameallplayer/<path:championshipid>/<path:playerid>', methods = [ 'GET'])
    const playerGamesURL =
      "https://csaka9.hu/teke/gameallplayer/" +
      reverse.bajnoksagid +
      "/" +
      playerIdNumber;

    const fetchPlayerGames = async () => {
      const response = await fetch(playerGamesURL);
      const newData = await response.json();
      setPlayerGames(newData);
    };

    if (playerIdNumber > 0) {
      fetchPlayerGames();
    }
  }, [reverse, playerIdNumber]);

  return (
    <>
      <HeaderContentHandler teljestabla={teljestabla} />
      <Container fluid>
        <Row>
          <div className="dropdown-titles">
            <div className="dropdown-title-item-jatekosok">Évad:</div>
          </div>
        </Row>
        <Row>
          <div className="buttons">
            <DropdownButton
              className="button1"
              id="dropdown-variants-secondary"
              variant="secondary"
              title={selectedEvad.year}
            >
              {evadok.map((data, idx) => (
                <Dropdown.Item key={idx} onClick={() => setSelectedEvad(data)}>
                  {data.year}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </Row>
      </Container>
      <Container fluid style={{ maxWidth: "90%" }}>
        <Row>
          <Col sm={7}>
            <div className="title title1 title-jatekos-mobil ">
              {selectedPlayerName}
            </div>
            <div>
              <h4
                style={{ fontWeight: "bold", borderBottom: "2px solid grey" }}
              >
                Kiválasztott évadban
              </h4>
              {teljestabla ? (
                <Row style={{ fontWeight: "bold" }}>
                  <Col xs={3} md={3}>
                    <img
                      src={
                        selectedPlayerPic
                          ? "https://feltoltes.csaka9.hu/" + selectedPlayerPic
                          : profilPictureTemp
                      }
                      alt="player"
                      className="jatekos-logok"
                    ></img>
                  </Col>
                  <Col sm={5} md={3} style={{ textTransform: "capitalize" }}>
                    <div>Név</div>
                    <div>Legtöbb ütött fa</div>
                    <div>átlag</div>
                    <div>összes meccs</div>
                    <div>hazai átlag</div>
                    <div>hazai meccsek</div>
                    <div>vendég átlag</div>
                    <div>vendég meccs</div>
                  </Col>
                  <Col sm={4} md={3}>
                    {selectedEvadStats.legtobbpont ? (
                      <>
                        <div>{selectedPlayerName}</div>
                        <div>{selectedEvadStats.legtobbpont}</div>
                        <div>{selectedEvadStats.osszatlag.toFixed(1)}</div>
                        <div>{selectedEvadStats.osszmeccs}</div>
                        <div>{selectedEvadStats.hazaiatlag.toFixed(1)}</div>
                        <div>{selectedEvadStats.hazaimeccsszam}</div>
                        <div>{selectedEvadStats.vendegatlag.toFixed(1)}</div>
                        <div>{selectedEvadStats.vendegmeccsszam}</div>
                      </>
                    ) : (
                        <>
                          <div>{selectedPlayerName}</div>
                          <div>0</div>
                          <div>0</div>
                          <div>0</div>
                          <div>0</div>
                          <div>0</div>
                          <div>0</div>
                          <div>0</div>
                        </>
                    )}
                  </Col>
                  <Col md={3}>
                    <div className="teamIconCenter">
                      <img
                        src={
                          csapatKep
                            ? "https://feltoltes.csaka9.hu/" + csapatKep
                            : teamIcon
                        }
                        alt="logo"
                        className="jatekos-csapat-logo"
                      ></img>
                    </div>
                    <div style={{ textAlign: "center" }}>{csapatName}</div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} style={{ textTransform: "capitalize" }}>
                    <div className="floatLeftMobile clear">
                      <img
                        src={
                          selectedPlayerPic
                            ? "https://feltoltes.csaka9.hu/" + selectedPlayerPic
                            : profilPictureTemp
                        }
                        alt="logo"
                        className="jatekos-logok"
                      ></img>
                    </div>
                    <div className="floatRightMobile">
                      <img
                        src={
                          csapatKep
                            ? "https://feltoltes.csaka9.hu/" + csapatKep
                            : teamIcon
                        }
                        alt="logo"
                        className="jatekos-csapat-logo"
                      ></img>
                    </div>
                    {selectedEvadStats.osszatlag > 0 && (
                      <div>
                        <div className="floatLeftMobile clear">Név</div>
                        <div className="floatRightMobile">
                          {selectedPlayerName}
                        </div>
                        <div className="floatLeftMobile clear">Csapat</div>
                        <div className="floatRightMobile">
                          <div style={{ textAlign: "right" }}>{csapatName}</div>
                        </div>
                        <div className="floatLeftMobile clear">
                          Legtöbb ütött fa
                        </div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.legtobbpont}
                        </div>
                        <div className="floatLeftMobile clear">átlag</div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.osszatlag.toFixed(1)}
                        </div>
                        <div className="floatLeftMobile clear">
                          összes meccs
                        </div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.osszmeccs}
                        </div>
                        <div className="floatLeftMobile clear">hazai átlag</div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.hazaiatlag.toFixed(1)}
                        </div>
                        <div className="floatLeftMobile clear">
                          hazai meccsek
                        </div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.hazaimeccsszam}
                        </div>
                        <div className="floatLeftMobile clear">
                          vendég átlag
                        </div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.vendegatlag.toFixed(1)}
                        </div>
                        <div className="floatLeftMobile clear">
                          vendég meccs
                        </div>
                        <div className="floatRightMobile">
                          {selectedEvadStats.vendegmeccsszam}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col sm={5}>
            <div className="title title-jatekos-mobil">Csapattagok</div>
            {jatekosok &&
              jatekosok.map((data, idx) =>
                data.id === playerIdNumber
                  ? data.name !== "Nem Jelent meg" && (
                      <Link
                        to={"/jatekosok/" + data.id}
                        className="jatekos-card-container-selected"
                        key={idx}
                      >
                        <div className="jatekos-card-selected">
                          <div className="jatekos-text-selected">
                            {data.name}
                          </div>
                        </div>
                      </Link>
                    )
                  : data.name !== "Nem Jelent meg" && (
                      <Link
                        to={"/jatekosok/" + data.id}
                        className="jatekos-card-container"
                        key={idx}
                      >
                        <div className="jatekos-card">
                          <div className="jatekos-text">{data.name}</div>
                        </div>
                      </Link>
                    )
              )}
          </Col>
        </Row>
        <Row>
          <h4
            style={{
              fontWeight: "bold",
              borderBottom: "2px solid grey",
              width: "100%",
            }}
          >
            Meccsek
          </h4>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th className="grid2-mid">Forduló</th>
                <th className="grid2-mid">Hazai</th>
                <th className="grid2-mid">Eredmény</th>
                <th className="grid2-mid">Vendég</th>
                <th className="grid2-mid">Helyszín</th>
                <th className="grid2-mid">Dátum</th>
              </tr>
            </thead>
            <tbody>
              {playerGames.map((data, idx) => (
                <tr key={idx} className="popup" onClick={() => openModal(data)}>
                  <td className="grid2-mid">{data.round}</td>
                  <td className="grid2-mid">{data.home}</td>
                  <td className="grid2-mid">
                    {data.homeSumCsp}-{data.awaySumCsp}
                  </td>
                  <td className="grid2-mid">{data.away}</td>
                  <td className="grid2-mid">{data.place}</td>
                  {data.matchdate ? (
                    <td className="grid2-mid">
                      {data.matchdate.slice(12, 16)}-
                      {data.matchdate.slice(8, 11)}-{data.matchdate.slice(5, 7)}
                    </td>
                  ) : null}
                </tr>
              ))}
              {showModal ? (
                <Modal
                  selectedModal={selectedModal}
                  teljestabla={teljestabla}
                  setShowModal={setShowModal}
                />
              ) : null}
            </tbody>
          </Table>
        </Row>
        <Row>
          <div className="title title1">Szezon Bontás</div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th className="grid2-mid kicsi">Évad</th>
                <th className="grid2-mid kicsi">Össz Meccsek</th>
                <th className="grid2-mid kicsi">Össz Átlag</th>
                <th className="grid2-mid kicsi">Hazai Meccsek</th>
                <th className="grid2-mid kicsi">Hazai Átlag</th>
                <th className="grid2-mid kicsi">Vendég Meccsek</th>
                <th className="grid2-mid kicsi">Vendég Átlag</th>
                <th className="grid2-mid kicsi">Legtöbb ütött fa</th>
                <th className="grid2-mid kicsi">Legtöbb ütött fa Dátuma</th>
              </tr>
            </thead>
            <tbody>
              {playerOverallStats.map((data, idx) => (
                <tr key={idx}>
                  <td className="grid2-mid kicsi">{data.evad}</td>
                  <td className="grid2-mid kicsi">{data.osszmeccs}</td>
                  <td className="grid2-mid kicsi">
                    {data.osszatlag.toFixed(1)}
                  </td>
                  <td className="grid2-mid kicsi">{data.hazaimeccsszam}</td>
                  <td className="grid2-mid kicsi">
                    {data.hazaiatlag.toFixed(1)}
                  </td>
                  <td className="grid2-mid kicsi">{data.vendegmeccsszam}</td>
                  <td className="grid2-mid kicsi">
                    {data.vendegatlag.toFixed(1)}
                  </td>
                  <td className="grid2-mid kicsi">{data.legtobbpont}</td>
                  {data.legtobbpontdatum ? (
                    <td className="grid2-mid kicsi">
                      {data.legtobbpontdatum.slice(0, 10)}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Jatekosok;
