import { useTable, useSortBy } from "react-table";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.4rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const BasicTable = (props) => {
  const data = props.eredmenyek;

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: "center" }}>Helyezés</div>,
        accessor: "helyezes",

        Cell: (row) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {row.value}
          </div>
        ),
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Csapat</div>,
        accessor: "name",
        Cell: (row) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {row.value}
          </div>
        ),
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Játszott</div>,
        accessor: "played",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Győzelem</div>,
        accessor: "win",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Döntetlen</div>,
        accessor: "draw",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Vereség</div>,
        accessor: "lost",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Büntető</div>,
        accessor: "penaty",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Nyert Szettek</div>,
        accessor: "setwin",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Vesztett Szettek</div>
        ),
        accessor: "setlost",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Szerzett Csapat Pontok</div>
        ),
        accessor: "teamwin",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Vesztett Csapat Pontok</div>
        ),
        accessor: "teamlost",

        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Pontszám</div>,
        accessor: "score",
        Cell: (row) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {row.value}
          </div>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <Styles>
      <div className="tableWrap -striped -highlight">
        <table
          {...getTableProps()}
          style={{ border: "solid 1px black", marginTop: "10px" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      background: "#dee2e6",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "white",
                        }}
                      >
                        {index === 1 ? (
                          <Link to={"/csapatok/" + row.original.id} style={{fontWeight: "bold"}}>{cell.value}</Link>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  );
};
