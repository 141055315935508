import { useTable, useSortBy } from "react-table";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 95%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
          }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`;


export const Bajnoksagshort = ({ eredmenyek }) => {
  const data = eredmenyek;

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Helyezés</div>
        ),
        accessor: "helyezes",

        Cell: row => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>{row.value}</div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Csapat</div>
        ),
        accessor: "name",

        Cell: row => (
          <Link to={"/csapatok/126"}
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {row.value}
          </Link>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Játszott</div>
        ),
        accessor: "played",

        Cell: row => (
          <div style={{ textAlign: "center" }}>{row.value}</div>
        )
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Pontszám</div>
        ),
        accessor: "score",
        Cell: row => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>{row.value}</div>
        )
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);
  return (
    <Styles>
      <div className="tableWrap">
        <table {...getTableProps()} style={{ border: "solid 1px black", marginTop: "10px"}}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      background: "#dee2e6",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "white",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  );
};
