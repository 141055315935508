import React, { useRef, useState, useEffect } from "react";
import ReactDom from "react-dom";
import { Points } from "./points/Points";
import { Games } from "./points/Games";
import { PointsShort } from "./points/PointsShort";
import { GamesShort } from "./points/GamesShort";

export const Modal = (props) => {
  const [oneMatch, setOneMatch] = useState([
    {
      awaySumCsp: 0,
      homeSumCsp: 0,
      id: 1,
      details: [
        {
          id: 0,
          playerdetails: [
            {
              anything: 0,
            },
          ],
        },
      ],
    },
  ]);

  //fetch getonematch
  useEffect(() => {
    const oneMatchURL = "https://csaka9.hu/teke/getonematch/" + props.selectedModal;

    const fetchOneMatch = async () => {
      const response = await fetch(oneMatchURL);
      const newData = await response.json();
      setOneMatch(newData);
    };

    fetchOneMatch();
  }, [props.selectedModal]);

  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      props.setShowModal(false);
    }
  };

  if (props.teljestabla) {
    return ReactDom.createPortal(
      //render the modal JSX in the portal div.
      <div
        className="modalcontainer"
        ref={modalRef}
        onClick={(e) => closeModal(e)}
      >
        <div className="modal">
          <Points oneMatch={oneMatch} />
          <Games oneMatch={oneMatch} setShowModal={props.setShowModal} />
          <button onClick={() => props.setShowModal(false)}>X</button>
        </div>
      </div>,
      document.getElementById("portal")
    );
  } else {
    return ReactDom.createPortal(
      //render the modal JSX in the portal div.
      <div
        className="modalcontainer"
        ref={modalRef}
        onClick={(e) => closeModal(e)}
      >
        <div className="modal">
          <PointsShort oneMatch={oneMatch} />
          <GamesShort oneMatch={oneMatch} setShowModal={props.setShowModal} />
          <button onClick={() => props.setShowModal(false)}>X</button>
        </div>
      </div>,
      document.getElementById("portal")
    );
  }
};
