import React, { useState } from "react";
import { Bajnoksagshort } from "./Bajnoksagshort";
import { BasicTable } from "./BasicTable";
import { Button } from "react-bootstrap";

export const ContentHandler = (props) => {
  const [tablaMutatas, setTablaMutatas] = useState(false);

  if (props.value) {
    return <BasicTable eredmenyek={props.eredmenyek} />;
  } else {
    if (tablaMutatas) {
      return (
        <>
          <Button variant="success" onClick={() => setTablaMutatas(false)}>
            Vissza
          </Button>
          <BasicTable eredmenyek={props.eredmenyek} selectedCsapat={props.selectedCsapat} />
        </>
      );
    } else {
      return (
        <>
          <Button variant="success" onClick={() => setTablaMutatas(true)}>
            Teljes Táblázat Megjelenítése
          </Button>
          <Bajnoksagshort eredmenyek={props.eredmenyek} />
        </>
      );
    }
  }
};
