import React from "react";
import Row from 'react-bootstrap/Row';
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export const Games = (props) => {
  const onPlayerClick = () => {
    window.scrollTo(0, 0);
    props.setShowModal(false)
  }

  return (
    <>
      <div className="grid4">
        <Row className="justify-content-around">
          <div className="title-popup">Meccs részletek</div>
        </Row>
        <Table striped hover bordered size="sm">
          <thead>
            <tr>
              <th className="grid2-mid">Játékosok</th>
              <th className="grid2-mid">Üf</th>
              <th className="grid2-mid">Összesen</th>
              <th className="grid2-mid">Szp</th>
              <th className="grid2-mid">Csp</th>
            </tr>
          </thead>
          <tbody>
            {props.oneMatch.map((data) =>
              data.details.map((game) => (
                <tr key={game.id}>
                  <td className="grid2-mid">
                    {game.homesubplayername
                      ? <>
                        <Link to={"/jatekosok/" + game.homeplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.homeplayername}
                        </Link> (<Link to={"/jatekosok/" + game.homesubplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.homesubplayername}
                        </Link>)
                      </>
                      : <Link to={"/jatekosok/" + game.homeplayerid} onClick={onPlayerClick}
                        className="grid2-mid"
                      >
                        {game.homeplayername}
                      </Link>}

                    <div className="homepdetail">{game.awaysubplayername
                      ? <>
                        <Link to={"/jatekosok/" + game.awayplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.awayplayername}
                        </Link> (
                        <Link to={"/jatekosok/" + game.awaysubplayerid} onClick={onPlayerClick}
                          className="grid2-mid"
                        >
                          {game.awaysubplayername}
                        </Link>)
                      </>
                      : <Link to={"/jatekosok/" + game.awayplayerid} onClick={onPlayerClick}
                        className="grid2-mid"
                      >
                        {game.awayplayername}
                      </Link>}
                    </div>
                  </td>
                  <td className="grid2-mid">
                    <div className="awaypdetail">
                      {game.playerdetails.map(
                        (pdetails) => pdetails.homeuf + " "
                      )}
                    </div>
                    <div className="homepdetail">
                      {game.playerdetails.map(
                        (pdetails) => pdetails.awayuf + " "
                      )}
                    </div>
                  </td>
                  <td className="grid2-mid">
                    <div className="awaypdetail">{game.homeuf}</div>
                    <div className="homepdetail">{game.awayuf}</div>
                  </td>
                  <td className="grid2-mid">
                    <div className="awaypdetail">{game.homeszp}</div>
                    <div className="homepdetail">{game.awayszp}</div>
                  </td>
                  <td className="grid2-mid">
                    <div className="awaypdetail">{game.homecsp}</div>
                    <div className="homepdetail">{game.awaycsp}</div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};
