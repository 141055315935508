import React from "react";
import { HeaderShort } from "./header/HeaderShort";
import { Header } from "./header/Header";

const HeaderContentHandler = (props) => {

    if (props.teljestabla) {
        return <Header />
    } else {
        return (
            <HeaderShort />
        )
    }
};

export default HeaderContentHandler;
