import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

export const Stats = (props) => {
  const [stats, setStats] = useState([
    {
      awayplayername: 0,
      homeplayername: 0,
      homeplayerid: 0,
      awayplayerid: 0,
      awayplayeruf: 0,
      homeplayeruf: 0,
      helyeyes: 1,
    },
  ]);

  useEffect(() => {
    const statsURL =
      "https://csaka9.hu/teke/stats/" +
      props.selectedFordulo +
      "/" +
      props.selectedBajnoksag;

    const fetchStats = async () => {
      const response = await fetch(statsURL);
      const newData = await response.json();
      setStats(newData);
    };

    fetchStats();
  }, [props.selectedBajnoksag, props.selectedFordulo]);

  return stats[0].homeplayeruf !== 0 && stats.awayplayeruf !== 0 ? (
    <>
      <div className="title">Statisztikák</div>
      <Table striped hover bordered size="sm">
        <thead>
          <tr>
            <th className="grid2-mid" colSpan="2">
              Hazai Pályán
            </th>
            <th className="grid2-mid">Helyezés</th>
            <th className="grid2-mid" colSpan="2">
              Vendég Pályán
            </th>
          </tr>
        </thead>
        <tbody>
          {stats.map((data, idx) => (
            <tr key={idx}>
              <td className="grid2-mid">
                {data.homeplayername !== "Összesen" ? (
                  <Link
                    to={"/jatekosok/" + data.homeplayerid}
                    className="grid2-mid"
                  >
                    {data.homeplayername}
                  </Link>
                ) : (
                  <div className="grid2-mid">{data.homeplayername}</div>
                )}
              </td>
              <td className="grid2-mid">{data.homeplayeruf}</td>
              <td className="grid2-mid">{data.helyeyes}</td>
              <td className="grid2-mid">{data.awayplayeruf}</td>
              <td className="grid2-mid">
                {data.homeplayername !== "Összesen" ? (
                  <Link
                    to={"/jatekosok/" + data.awayplayerid}
                    className="grid2-mid"
                  >
                    {data.awayplayername}
                  </Link>
                ) : (
                  <div className="grid2-mid">{data.homeplayername}</div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  ) : (
    <></>
  );
};
