import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React from "react";

export const Footer = () => {
    return (
        <Container fluid>
            <Row className="justify-content-md-center copyr">
                <div>
                    Copyright © 2021&nbsp;Magyar Tekeszövetség. Minden jog fenntartva.
                </div>
            </Row>
        </Container>
    );
}