import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Csapatok from "./pages/Csapatok";
import Jatekosok from "./pages/Jatekosok";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "csapatok/:csapatId",
    element: <Csapatok />,
  },
  {
    path: "jatekosok/:playerId",
    element: <Jatekosok />,
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
