import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import React from "react";

export const KeresztTabla = (props) => {
    const hazaiAtlagokKeys = Object.keys(props.keresztTabla.hazaiatlagok);
    const csapatNevek = createCsapatNevek();
    const hazaiAtlagokValues = Object.values(props.keresztTabla.hazaiatlagok); //numbers
    const hazaiAtlagok = createHazaiAtlagok();
    const sorokMeccsei = createSorokMeccsek();

    function createCsapatNevek() {
        let arr = [];
        for (let i = 0; i < hazaiAtlagokKeys.length; i = i + 3) {
            let csapatToPush = props.csapatok.filter(csapat => csapat.name === hazaiAtlagokKeys[i])
            let objectToPush = { csapatName: hazaiAtlagokKeys[i], csapatId: csapatToPush.id }
            arr.push(objectToPush)
        };

        return arr;
    }

    function createSorokMeccsek() {
        let array = [];
        csapatNevek.forEach((csapat) => {
            array.push(
                props.keresztTabla.meccsek.filter((meccs) => {
                    return (meccs.hazainev === csapat.csapatName)
                })
            )
        })
        return array;
    }

    function createHazaiAtlagok() {
        let hazaiAtlagokArr = [];
        for (let i = 1; i < hazaiAtlagokValues.length; i = i + 3) {
            hazaiAtlagokArr.push(hazaiAtlagokValues[i])
        };
        return hazaiAtlagokArr;
    }

    return (
        <Table responsive striped bordered hover size="sm"
        style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} className="kereszttabla-mobil">
            <thead>
                <tr style={{ fontSize: "0.6em" }}>
                    <th>{props.selectedEvad.year}</th>
                    {csapatNevek &&
                        csapatNevek.map((key, idx) => {
                            return (
                                <th key={idx}>
                                    {key.csapatName}
                                </th>
                            )
                        })
                    }
                    <th>Hazai Átlag</th>
                </tr>
            </thead>
            <tbody style={{ fontSize: "0.6em" }}>
                {csapatNevek &&
                    //csapatnevek a hazaiatlagok kulcsbol
                    csapatNevek.map((key, idx) => {
                        return (
                            <tr key={idx}>
                                <th><Link to={"/csapatok/126"}>
                                    {key.csapatName}
                                </Link></th>
                                {/* minden csapatnévhez egy sornyi */}
                                {csapatNevek.map((key2, idy) => {
                                    const isFound = sorokMeccsei[idx].some(element => {
                                        if (element.vendegnev === key2.csapatName) {
                                            return true;
                                        }   
                                        return false;
                                    });
                                    if (isFound) {
                                        let result = sorokMeccsei[idx].filter(obj => {
                                            return (obj.vendegnev === key2.csapatName)
                                        })
                                        return (<td key={idy}>
                                            {result[0].hazaisumuf}-{result[0].vendegsumuf}
                                            {/* {result[0].hazaisumuf}:{result[0].vendegsumuf} */}
                                        </td>)
                                    } else {
                                        return (<td key={idy}></td>)
                                    }
                                })}
                                <th>{hazaiAtlagok[idx].toFixed(1)}</th>
                            </tr>
                        )
                    })
                }
                <tr>
                    <th>Idegen Átlag</th>
                    {props.keresztTabla.hazaiatlagok &&
                        Object.values(props.keresztTabla.vendegatlagok).map((value, idx) => {
                            if (idx % 3 === 1) {
                                return (
                                    <th key={idx}>
                                        {value.toFixed(1)}
                                    </th>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </tr>
            </tbody>
        </Table>
    );
}