import React from "react";
import Table from "react-bootstrap/Table";

export const Points = ({ oneMatch }) => {
  return (
    <>
      <div className="grid4">
        <h2 className="points-grid-left clear">{oneMatch[0].hometeamname}</h2>
        <h2 className="points-grid-right">{oneMatch[0].awayteamname}</h2>
        {oneMatch[0].awaySumCsp || oneMatch[0].homeSumCsp ? (
          <h2 className="grid2-mid">
            {oneMatch[0].homeSumCsp} - {oneMatch[0].awaySumCsp}
          </h2>
        ) : (
          <h2 className="grid2-mid">Végeredmény nem elérhető</h2>
        )}
        <Table striped hover bordered size="sm">
          <thead>
            <tr>
              <th className="grid2-mid">Hazai Üf</th>
              <th className="grid2-mid">Hazai Szp</th>
              <th className="grid2-mid">Vendég Szp</th>
              <th className="grid2-mid">Vendég Üf</th>
            </tr>
          </thead>
          <tbody>
            {oneMatch.map((data) => (
              <tr key={data.id.toString()}>
                <td className="grid2-mid">{data.homeSumUf}</td>
                <td className="grid2-mid">{data.homeSumSzp}</td>
                <td className="grid2-mid">{data.awaySumSzp}</td>
                <td className="grid2-mid">{data.awaySumUf}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
